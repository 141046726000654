<template>
  <div class="header">
    <router-link class="back-btn" to="/">
      ≤ <span>BACK</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss" scoped>
.header {
  padding: 33px 22px;
  position: relative;
}

.back-btn {
  font-family: 'akkurat-mono2';
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 1.042vw;
  text-decoration: none;
  color: #000000;
  padding-right: 2px

  span {
    display: inline-block;
    font-size: 1.146vw;
    line-height: 1.563vw;
    margin-left: -10px;
  }
}

@media screen and (min-width: 1200px) {
  .back-btn {
    color: #9D9D9D;
    padding-right: 2px;
    font-size: 12px;
  }
}

/* Media query for smartphones */
@media only screen and (max-width: 600px) {
 .back-btn { font-size: 12px;
  padding-right: 2px;
 }

}

@media only screen and (max-width: 400px) {
 .back-btn { font-size: 12px;
  padding-right: 2px;
 }
 
}

@media only screen and (max-width: 375px) {
 .back-btn { font-size: 12px;
  padding-right: 2px;
 }

}

</style>
