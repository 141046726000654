<template>
  <div class="plans-pricing-page">

      <Header/>
      <div class="plans-pricing__wrapper">
        <div class="app">
           <!-- Display the message as long as we are in step 1 or 2 -->
           <div v-if="step <= messages.length && !showEmailLink" class="message">
             <span>{{ displayedText }}</span>
           </div>

           <!-- Display the actionable email link after hiding the second message -->
           <div v-if="showEmailLink" class="form">
             <span>
               <a :href="'mailto:info@str-ing.org'" class="email-link"
               >info@str-ing.org</a>
             </span>
           </div>
        </div>
      </div>
    <Navigation class="plans-pricing__navigation" />
  </div>
  
</template>
<script>
import Header from "../components/PlansPricing/Header";
import Navigation from "../components/Navigation";
export default {
  name: "PlansPricing",
  components: {Navigation, Header},
  data() {
    return {
      step: 1, // Tracks which message step we're on
      messages: [
        "Instantly reveal the global influence and reach of your collection", // First long message
        "Book a demo", // Second message to be shown before email link
      ],
      displayedText: "", // Stores the progressively typed text
      typingSpeed: 100, // Speed of typing effect (milliseconds)
      showEmailLink: false, // Controls visibility of the email link
    };
  },
  mounted() {
    this.typeMessage();
  },
  methods: {
    typeMessage() {
      let currentMessage = this.messages[this.step - 1];
      if (currentMessage.length > 40) {
        const breakIndex = this.findBreakIndex(currentMessage);
        currentMessage = `${currentMessage.slice(
          0,
          breakIndex
        )}\n${currentMessage.slice(breakIndex)}`;
      }
      let charIndex = 0;
      const typingInterval = setInterval(() => {
        if (charIndex < currentMessage.length) {
          this.displayedText += currentMessage[charIndex];
          charIndex++;
        } else {
          clearInterval(typingInterval);
          if (this.step === 2) {
            // Delay before hiding "Book a demo" and showing the email link
            setTimeout(() => {
              this.displayedText = "";
              this.showEmailLink = true; // Show the email link after hiding the message
            }, 2000); // 2-second delay before showing the email link
          } else {
            setTimeout(() => {
              this.moveToNextStep();
            }, 1000);
          }
        }
      }, this.typingSpeed);
    },
    findBreakIndex(message) {
      let breakIndex = 40;
      while (breakIndex > 0 && message[breakIndex] !== " ") {
        breakIndex--;
      }
      return breakIndex || 40;
    },
    moveToNextStep() {
      if (this.step < this.messages.length) {
        this.step++;
        this.displayedText = "";
        this.typeMessage();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.plans-pricing-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.plans-pricing__wrapper {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  /* Center vertically */
  align-items: center; /* Center horizontally */
  height: calc(100% - 98px);
}

.center-content {
  font-size: 24px; /* You can style this as needed */
}

.plans-pricing__navigation {
 /* display: none;*/
}
.app {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.message {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  white-space: pre-wrap; /* To support line breaks with \n */
}

.form {
  margin-top: 20px;

  .email-link {
    font-size: 18px;
    color: #007bff; /* Blue color for the link */
    text-decoration: underline;
    cursor: pointer;
  }

  .email-link:hover {
    color: #0056b3; /* Darker blue on hover */
  }
}
@media screen and (min-width: 1200px) {
  .plans-pricing-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .plans-pricing__navigation {
     display: unset;
  }
}
/* Media query for smartphones */
@media only screen and (max-width: 600px) {
.plans-pricing-page {
    min-height: 95vh !important; }
}

@media only screen and (max-width: 375px) {
  .plans-pricing-page {
    min-height: 85vh !important; }
}

</style>
